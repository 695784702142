
$(function () {
    document_ready();
});

function document_ready() {
    ts = (new Date()).getTime();

    responsive_init();

    $("body").addClass("ready").removeClass("no-transition");

    init_event_handlers();
    bind_widgets();

    te = (new Date()).getTime();
    console.log("On-Ready Load Time: ", te - ts);
}

$(window).scroll(function () {
    scroll_animations();
});

$(window).on("resize", function (e) {
    responsive_update(null, true);
    scroll_animations();
});

function init_event_handlers() {
    fix_100vh_init();
    icons_load();
    blazy_init();
    goto_init();
    scroll_animations();
    fix_touch_hovers();
    click_touch_init();
    header_init();
    form_process_init();
    expand_it_init();
    misc_init();
}

function bind_widgets(context) {
    img_to_bg(null, context);
    validate_init();
    slider_init(null, context);
    textfield_init(null, context);
    attachCaseStudyTabs();
}