
/*
* Form handlers
* */


function form_process_init() {
    const GOOGLE_CAPTCHA_SITE_ID = "6LevSiAoAAAAAN1zqXw-ZYNEaPgXZJTSRj1yGRzB";

    $(document).on("submit.valid click perform-action", ".form--contact", function (e) {
        var $this = $(this);
        if ($this.is("form") && e.type != "submit") {
            return true;
        }
        e.preventDefault();
        var url = $this.attr("href");
        var method = "get";
        var data = null;

        $this.find('.js-file-upload-hash').val(new Date().getTime());

        if ($this.is("form")) {
            url = $this.attr("action");
            if ($this.attr("method")) {
                method = $this.attr("method");
            }
        }
        if ($this.attr("data-action-url")) {
            url = $this.attr("data-action-url");
        }
        if ($this.attr("data-action-method")) {
            method = $this.attr("data-action-method");
        }

        if ($this.is("form")) {
            data = $this.serialize();

            if (method.toLowerCase() != 'get' && window.FormData !== undefined) {
                data = new FormData($this[0]);
            }
            $this.data('serialized', data);
            $this.data('serializedArray', $this.serializeArray());

        }


        if ($this.find('.js-file-upload-start').length) {
            form_process_before_send($this);
            $this.find('.js-file-upload-start').trigger('click');
        }
        else {
            window.grecaptcha.ready(async function () {
                const token = await window.grecaptcha.execute(GOOGLE_CAPTCHA_SITE_ID, { action: 'submit' });

                //https://stackoverflow.com/questions/41431322/how-to-convert-formdatahtml5-object-to-json
                var object = {};
                data.forEach(function (value, key) {
                    object[key] = value;
                });
                object.token = token

                var json_data = JSON.stringify(object);
                
                $.ajax({
                    url: url,
                    method: method,
                    timeout: 0,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: json_data,
                    beforeSend: function () {
                        form_process_before_send($this);
                    },
                    complete: function (response) {
                        form_process_complete($this, response);
                    },
                    success: function (response) {
                        form_process_errors_clear($this);
                        form_process_success($this, response);
                    },
                    error: function (jqXHR) {
                        form_process_errors_clear($this);
                        form_process_errors($this, jqXHR);
                    }
                });
            });
        }
    });

    $(document).on("after-action-success", ".js-action-reset-after", function (e, response) {
        var is_success = form_response_is_success($(this), response);
        if (is_success) {
            $(this)[0].reset();
        }
    });

    $(document).on("change", ".js-submit-on-input-change :input", function (e) {
        $(this).closest('form').trigger('submit');
    });
}

function form_process_before_send($this) {
    $this.find(":input").prop("disabled", true).closest(".textfield-wrapper").addClass("disabled");
    $this.find(".js-form-disable-on-submit").addClass("disabled");
    var $btn = $this.find(".loader-on-submit");
    if (window['loader_add']) {
        loader_add($btn);
    }
}

function form_process_complete($this, response) {
    $this.find(":input").not("[data-disabled]").prop("disabled", false).closest(".textfield-wrapper").removeClass("disabled");
    $this.find(".js-form-disable-on-submit").not("[data-disabled]").removeClass("disabled");
    $this.find(":password").val("");
    var $btn = $this.find(".loader-on-submit");
    if (window['loader_remove']) {
        loader_remove($btn);
    }
    $this.trigger("after-action-complete", response);
}


function form_response_is_success($this, response) {
    // successfull submission returns sid
    let is_success = false;
    if (response == "Accepted") {
        is_success = true;
    }
    return is_success;
}

function form_process_success($this, response) {
    var is_success = form_response_is_success($this, response);
    if (is_success) {
        $this.trigger("after-action-success", response);
        var $message = $this.find(".js-message-container");
        $this.find(".js-message-container").html("Message received, we will contact you soon.");
        //remove the button
        $this.find(".form__actions").html();
        bind_widgets($this);
        $message.addClass("active");
        $this.addClass("inactive");
        if ($message.offset().top < $(window).scrollTop() + $(".header").outerHeight()) {
            goto_object($message, null, $(".header").outerHeight() * -1);
        }
        // setTimeout(function(){
        //     $message.removeClass("active");
        //     $this.removeClass("inactive");
        //     $this.find(".js-message-container").html("");
        //     document.getElementById("contact_form").reset();
        // }, 3000);
    }
    else {
        var error_text;
        if (response.errors) {
            form_process_errors_show($this, response);
        }
        else if (response.text) {
            error_text = response.text;
        }
        else {
            error_text = 'Error occurs. Try again.';
        }
        if (error_text) {

        }
    }
    $this.trigger("after-action-success", response);
}

function form_process_errors($this, jqXHR) {
    console.log(jqXHR.status, jqXHR.responseJSON);
    var error_text;
    if (jqXHR.status === 401) {
        error_text = 'Access denied.';
    }
    else if (jqXHR.status === 422) {
        form_process_errors_show($this, jqXHR.responseJSON);
    }
    else {
        error_text = 'Error occurs. Try again.';
    }
    if (error_text) {
        alert(error_text);
    }
    $this.trigger("after-action-error", jqXHR);
}

function form_process_errors_clear($this) {
    $this.find('.form-process-error').removeClass('error form-process-error');
    $this.find('.form__error--form-process-error').remove();
}

function form_process_errors_show($this, response) {
    var index = 0;
    form_process_errors_clear($this);
    $.each(response.errors, function (field_name, field_errors) {
        $field = $this.find("[name='" + field_name + "']");
        $field.addClass("error form-process-error");
        if (!index) {
            setTimeout(function () {
                $field.trigger("focus");
            }, 100);
        }
        var $append_to = $field.closest('.form__item-field');
        if (!$append_to.length) {
            $append_to = $field.parent();
        }
        $append_to.find('.form__error').remove();
        if (!Array.isArray(field_errors)) {
            field_errors = [field_errors];
        }
        $.each(field_errors, function (error_index, error_text) {
            $append_to.append("<div id='" + field_name + "-error' class='form__error form__error--form-process-error'>" + error_text + "</div>");
        });
        index++;
    });
}



/*

 Form Validation

 */

function validate_init(context) {
    if (!$.validator) return;

    if (!$("body").hasClass("validate-inited")) {

        $(document).on("reset", ".form-validate", function () {
            var $form = $(this);
            $form.find(".form__item, :input").removeClass("error");
            setTimeout(function () {
                $form.find(":input").trigger("change");
                $form.find(".js-checkbox input[type='checkbox'], .js-checkbox input[type='radio']").trigger("change-pseudo");
            }, 50);
        });

        $.extend($.validator.messages, {
            required: "Required field.",
            email: "Invalid E-mail.",
            uname: "Invalid name.",
            phone: "Uncompleted phone number.",
            phonecomplete: "Uncompleted phone number.",
            emailphone: "Invalid E-mail or Phone.",
            maskcomplete: "Fill this field completely.",
            datetime: "Incorrect date or time.",
            dateexist: "This date or time does not exist.",
            password: "Password must contain more than 6 symbols.",
            passwordconfirm: "Does not match with password.",
            remote: "Please fix this field.",
            url: "Please enter a valid URL.",
            dateonly: "Please enter a valid date.",
            date: "Please enter a valid date.",
            dateISO: "Please enter a valid date (ISO).",
            number: "Please enter a valid number.",
            digits: "Please enter only digits.",
            creditcard_expires: "Invalid expery date.",
            creditcard_cvc: "Invalid CVC format.",
            equalTo: "Please enter the same value again.",
            maxlength: $.validator.format("Please enter no more than {0} characters."),
            minlength: $.validator.format("Please enter at least {0} characters."),
            rangelength: $.validator.format("Please enter a value between {0} and {1} characters long."),
            range: $.validator.format("Please enter a value between {0} and {1}."),
            max: $.validator.format("Please enter a value less than or equal to {0}."),
            min: $.validator.format("Please enter a value greater than or equal to {0}."),
            step: $.validator.format("Please enter a multiple of {0}.")
        });

        var lang = $('html').attr('lang');
        switch (lang) {
            case 'ru':
                break;
            default:
        }

        $.validator.addMethod("email", function (value, element) {
            return this.optional(element) || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
        });

        $.validator.addMethod("uname", function (value, element) {
            return this.optional(element) || /^[a-z\u00C0-\u01FF\u0400-\u04FF'][ a-z\u00C0-\u01FF\u0400-\u04FF'\-]*[a-z\u00C0-\u01FF\u0400-\u04FF]$/i.test(value); // european and cyrillic names
        });

        $.validator.addMethod("phone", function (value, element) {
            var digits = value.replace(/\D/g, "").length;
            var others = value.replace(/[\d\s]/g, "").length;
            return this.optional(element) || (digits >= 7 && digits > others);
        });

        $.validator.addMethod("emailphone", function (value, element) {
            return this.optional(element) || $.validator.methods.email.call(this, value, element) || $.validator.methods.phone.call(this, value, element);
        });

        $.validator.addMethod("phonecomplete", function (value, element) {
            return this.optional(element) || (value.replace(/\D/, "").length >= 7 && $(element).inputmask('isComplete'));
        });

        $.validator.addMethod("maskcomplete", function (value, element) {
            return this.optional(element) || $(element).inputmask('isComplete');
        });

        $.validator.addMethod("password", function (value, element) {
            return this.optional(element) || value.length >= 6;
        });

        $.validator.addMethod("passwordconfirm", function (value, element) {
            var $password = $($(element).data("rule-passwordconfirm-password"));
            return this.optional(element) || !$password.length || !$password.val() || value === $password.val();
        });

        $.each($.validator.methods, function (key, value) {
            $.validator.methods[key] = function () {
                if (arguments.length > 0) {
                    arguments[0] = $.trim(arguments[0]);
                }
                return value.apply(this, arguments);
            };
        });

        $(document).on("keyup blur change check-form", ".form-disabled-until-valid :input", function () {
            var $form = $(this).closest(".form-disabled-until-valid");
            var result = $form.validate().checkForm();
            var $if_filled = $form.find('.form-disabled-until-valid-check-filled');
            var is_filled = $if_filled.filter('.filled').length == $if_filled.length;
            if ($if_filled.length) {
                result = result && is_filled;
            }
            var $button = $form.find(".disabled-until-valid");
            $button.prop('disabled', !result);
        });

        $(document).on("change change-checkbox-valid", "input[type='checkbox'], input[type='radio']", function () {
            var $form = $(this).closest("form");
            var validator = $form.data("validator");
            if (validator) $(this).valid();
        });

        $("body").addClass("validate-inited");
    }

    var $forms = context ? $('.form-validate', context) : $('.form-validate');
    $forms = $forms.not(".form-validate-inited");
    $forms.each(function () {
        $(this).validate({
            onkeyup: false,
            normalizer: function (value) {
                return $.trim(value);
            },
            errorClass: 'form__error',
            validClass: 'valid',
            errorElement: 'div',
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('error').removeClass('valid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).addClass('valid').removeClass('error');
            },
            errorPlacement: function (error, element) {
                $(element).closest(".form__item-field").append(error);
            },
            submitHandler: function (form, e) {
                $(form).trigger("submit.valid");
            }
        });

        $(this).addClass("form-validate-inited")
    });

    $(".form-disabled-until-valid-onload").find(":input").first().trigger("check-form");

}