/**
 * Provides tabs.
 */

(function (root) {

    'use strict';

    const pluginName = 'DruimTabs';

    function Plugin(element) {
        this.targetElement = element;
        this.init();
    }

    Plugin.prototype = {
        init: function () {
            this.tabsElement = this.targetElement.querySelector('[data-tabs]');
            this.tabElements = this.targetElement.querySelectorAll('[data-tab]');
            this.tabItemsElement = this.targetElement.querySelector('[data-tab-items]');
            this.tabItemElements = this.targetElement.querySelectorAll('[data-tab-item]');
            this.tabSlideElement = this.targetElement.querySelector('[data-slide]');

            this.tabElements.forEach((tabElement, index) => {
                tabElement.addEventListener('click', (event) => {
                    event.preventDefault();
                    this.setActiveTab(index);
                });
            });

            this.setActiveTab(0);
        },

        hasSlide: function () {
            return typeof this.tabSlideElement !== 'undefined';
        },

        setActiveTab(tabIndex) {
            this.activeTab = tabIndex;
            this.updateState();
            this.hasSlide();
        },

        updateState() {
            this.tabElements.forEach((tabElement, index) => {
                if (index === this.activeTab) {
                    tabElement.classList.add('tabs__tab--active');
                }
                else {
                    tabElement.classList.remove('tabs__tab--active');
                }
            });

            this.tabItemElements.forEach((tabItemElement, index) => {
                if (index === this.activeTab) {
                    tabItemElement.classList.add('tabs__tab-item--active');
                }
                else {
                    tabItemElement.classList.remove('tabs__tab-item--active');
                }
            });

            if (this.hasSlide()) {
                const activeTabElement = this.tabElements[this.activeTab];
                const computedStyle = getComputedStyle(activeTabElement);
                const innerWidth = activeTabElement.clientWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight);
                this.tabSlideElement.style.width = innerWidth + 'px';
                const left = activeTabElement.offsetLeft + activeTabElement.scrollLeft + parseFloat(computedStyle.paddingLeft);
                this.tabSlideElement.style.left = left + 'px';
            }
        },
    }

    root[pluginName] = Plugin;

})(window);