
/*

 Textfields (with reset icon, adaptive placeholders etc)

 */

function textfield_init($o, context) {
    if (typeof $o === "undefined") $o = $(".textfield");

    if (!$("body").hasClass("textfield-inited")) {

        $(document).on("keydown keyup focus blur textfield-filled", ".textfield", function (e) {
            var is_filled = !!$(this).val();
            // if (e.type === "textfield-filled") { // remove inline attribute value for CSS [value] selector purpose
            //     if ($(this).val().length) $(this).prop("value", $(this).val());
            //     $(this).removeAttr("value");
            // }
            $(this).toggleClass("filled", is_filled);
        });
        // $(document).ajaxStop(function() {
        //     setTimeout(function(){
        //         $(".textfield").trigger("textfield-filled");
        //     }, 50);
        // });

        $("body").addClass("textfield-inited")
    }
    if (!$o) {
        $o = context?$('.textfield', context):$('.textfield');
    }
    $o.trigger("textfield-filled");
}