/*
* SVG icon url update
* */

function icons_load() {
    var url = $('body').data('svg-sprite-url');
    $.ajax({
        url: url,
        localCache: true,
        cacheTTL: 1,
        dataType: 'text',
        cacheKey: 'svg-sprite'
    }).done(function (response) {
        $('body').append('<div id="svg-icons-container" style="height: 0; width: 0; position: absolute; top: -99999px; left: 0; visibility: hidden;">' + response + '</div>');
    });
}


/*

 Loaders (for buttons)

 */

function loader_init() {
    $btn = $(".loader-on-page-load");
    loader_add($btn);
    $btn.prop("disabled", true);
}

function loader_add($o) {
    var loader = '<span class="loader"><svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\
    width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">\
        <path fill="#fff" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">\
        <animateTransform attributeType="xml"\
            attributeName="transform"\
            type="rotate"\
            from="0 25 25"\
            to="360 25 25"\
            dur="0.6s"\
            repeatCount="indefinite"/>\
        </path>\
        </svg></div>';
    if ($o.closest('.btn').length) {
        $o.wrapInner("<div class='btn__loader-inner'>");
    }
    $o.append(loader).addClass("loading");
    var $loader = $o.find("> .loader");
    $loader.addClass("active");
}

function loader_remove($o) {
    $o.find(".btn__loader-inner").children().unwrap();
    $o.removeClass("loading");
    var $loader = $o.find("> .loader");
    $loader.removeClass("active");
    $loader.remove();
}


/*

Slider

 */


function slider_init($s, context) {
    slider_swiper_init($s, context);
}

function slider_swiper_init($s, context, on_init) {
    if (typeof Swiper !== "undefined") {

        if (!$("html").data("swiper-inited")) {
            $("html").data("swiper-inited", true);

            $(window).on('load', function(e){
                var $slider = $('.js-swiper[data-swiper-autoplay-on-load="true"]');
                $slider.each(function(){
                    var $swiper = $(this).find('.swiper-container');
                    if ($swiper.length && $swiper[0].swiper) {
                        $swiper[0].swiper.autoplay.start();
                    }
                });
            });

        }


        if (typeof $s === "undefined" || !$s) {
            $s = context ? $('.js-swiper', context) : $('.js-swiper');
            $s = $s.not(".js-swiper-inited").not(".js-swiper-on-demand");
        }
        $s.each(function () {
            var $this = $(this);
            var pagination = {
                el: (typeof $this.data("slider-pagination") !== "undefined") ? $this.data("slider-pagination") : $this.find('.swiper-pagination')[0],
                type: (typeof $this.data("slider-pagination-type") !== "undefined") ? $this.data("slider-pagination-type") : 'bullets',
                bulletElement: (typeof $this.data("slider-pagination-element") !== "undefined") ? $this.data("slider-pagination-element") : 'span',
                clickable: (typeof $this.data("slider-pagination-clickable") !== "undefined") ? $this.data("slider-pagination-clickable") : true,
                renderBullet: function (index, className) {
                    var $slider = $(this.$el).closest(".js-swiper");
                    var name = $(this.slides).eq(index).attr("data-pagination-name");
                    var summary = $(this.slides).eq(index).attr("data-pagination-summary");
                    var tag = 'span';
                    if ($slider.length) {
                        if ($slider.data("slider-pagination-element")) {
                            tag = $slider.data("slider-pagination-element");
                        }
                    }
                    if (!name) {
                        name = "";
                    } else {
                        name = "<div class='swiper-pagination-name'>" + name + "</div>";
                    }
                    if (!summary) {
                        summary = "";
                    } else {
                        summary = "<div class='swiper-pagination-summary'>" + summary + "</div>";
                    }
                    var timer_inner = '<svg class="btn-pie" viewbox="0 0 52 52" width="52" height="52" xmlns="http://www.w3.org/2000/svg">' +
                        '<circle class="btn-pie__circle" stroke="#2F97FC" stroke-linecap="round" fill="none" cx="26" cy="26" r="23" transform="rotate(-90 26 26)" />' +
                        '</svg>';
                    var timer = '<div class="swiper-pagination-bullet-timer">'+timer_inner+'</div>';
                    return '<' + tag + ' class="' + className + '">' + name + summary + timer + '</' + tag + '>';
                },
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' + '<span class="slider-pagination-slash"> / </span>' + '<span class="' + totalClass + '"></span>';
                },
                formatFractionCurrent: function (number) {
                    return String("00" + number).slice(-2);
                },
                formatFractionTotal: function (number) {
                    return String("00" + number).slice(-2);
                },
            };
            console.log(pagination);
            var scrollbar = {
                el: $this.find('.swiper-scrollbar')[0],
                draggable: (typeof $this.data("slider-scrollbar-draggable") !== "undefined") ? $this.data("slider-scrollbar-draggable") : true,
                hide: (typeof $this.data("slider-scrollbar-hide") !== "undefined") ? $this.data("slider-scrollbar-hide") : false,
                snapOnRelease: (typeof $this.data("slider-scrollbar-snap-on-release") !== "undefined") ? $this.data("slider-scrollbar-snap-on-release") : true
            };
            var defaults = {
                init: false,
                pagination: pagination,
                scrollbar: ($this.find('.swiper-scrollbar').length) ? scrollbar : {},
                fadeEffect: {
                    crossFade: true
                },
                loadOnTransitionStart: true,
                navigation: {
                    prevEl: $this.find('.swiper-button-prev'),
                    nextEl: $this.find('.swiper-button-next'),
                },
                autoHeight: false,
                speed: 700,
                buttonDisabledClass: 'disabled',
                roundLengths: true,
                resistanceRatio: 0,
            };
            var options = defaults;
            options = $.extend(true, {}, defaults, $this.data('swiper'));
            var swiper = new Swiper($this.find('.swiper-container')[0], options);

            swiper.on('transitionStart', function (e) {
                this.$el.trigger('transitionStart', [this]);
                var $activeSlide = $(this.slides).eq(this.activeIndex);
                $activeSlide.find(".swiper-lazy-preloader").css({
                    top: this.height / 2
                });
                var $activeSlide = $(this.slides).eq(this.activeIndex);
            });

            swiper.on('transitionEnd', function () {
                this.$el.trigger('transitionEnd', [this]);
            });

            if (on_init) {
                swiper.on('init', on_init);
            }

            swiper.on('init', function (e) {
                this.$el.trigger('init', [this]);
                if ($this.data('swiper-autoplay-manual')) {
                    this.autoplay.stop();
                }
            });

            swiper.on('init progress resize', function (e) {
                if (typeof this.allowTouchMoveRemembered === 'undefined') {
                    this.allowTouchMoveRemembered = this.allowTouchMove;
                }
                var $slider = $(this.$el).closest(".js-swiper");
                if (this.isBeginning && this.isEnd) {
                    $slider.addClass("swiper-buttons-unneeded");
                    $(this.navigation.$prevEl).addClass("swiper-button-unneeded");
                    $(this.navigation.$nextEl).addClass("swiper-button-unneeded");
                    this.allowTouchMove = false;
                } else {
                    $slider.removeClass("swiper-buttons-unneeded");
                    $(this.navigation.$prevEl).removeClass("swiper-button-unneeded");
                    $(this.navigation.$nextEl).removeClass("swiper-button-unneeded");
                    if (typeof this.allowTouchMoveRemembered !== 'undefined') {
                        this.allowTouchMove = this.allowTouchMoveRemembered;
                    }
                }
            });

            swiper.on('progress', function (progress) {
                this.$el.trigger('progress', [this]);
                progressCallback(this);
            });
            progressCallback($this.find('.swiper-container')[0].swiper);


            swiper.on('lazyImageReady', function (slideEl, imageEl) {
                this.updateAutoHeight();
                img_to_bg($(imageEl).closest('.img-to-bg').addClass('img-to-bg-lazy-loaded'))
            });

            swiper.on('transitionEnd progress', $.throttle(50, function () {
                if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                    Blazy.revalidate();
                }
            }));

            swiper.on('autoplayStart', function (e) {
                this.autoplay.started = true;
                $(this.el).addClass('swiper-container-autoplay');
                if (this.params.speedAfterAutoplayStart) {
                    this.params.speed = this.params.speedAfterAutoplayStart;
                }
            });

            swiper.on('autoplay', function (e) {
                this.autoplay.once = true;
            });

            swiper.on('autoplayStart autoplay resize', function (e) {
                var prefix = 'swiper-pagination-bullet';
                var $all = $(this.el).find('.' + prefix);
                var $active = $all.filter('.' + prefix + '-active');
                var $next = $active;
                if ($next.length) {
                    swiper_bullet_timer_append(this, $all, $next, prefix);
                }
            });

            swiper.on('transitionStart', function (e) {
                var prefix = 'swiper-pagination-bullet';
                var $all = $(this.el).find('.' + prefix);
                var $active = $all.filter('.' + prefix + '-active');
                swiper_bullet_timer_append(this, $all, $active, prefix, this.params.speed);
            });

            swiper.on('autoplayStop resize', function () {
                var prefix = 'swiper-pagination-bullet';
                var $all = $(this.el).find('.' + prefix);
                $all.find('.' + prefix + '-timer').removeClass(prefix + '-timer-go').css('transition-duration', '');
                this.autoplay.started = false;
                $(this.el).removeClass('swiper-container-autoplay');

                if (!this.params.speedAfterAutoplayStopManualOnce) {
                    if ($(this.el).closest('.js-swiper').data('swiper-autoplay-manual')) {
                        this.params.speedAfterAutoplayStopManualOnce = true;
                    }
                } else {
                    this.params.speedAfterAutoplayStopManualOnce = false;
                }
                if (this.params.speedAfterAutoplayStop && !this.params.speedAfterAutoplayStopManualOnce) {
                    this.params.speed = this.params.speedAfterAutoplayStop;
                    this.params.speedAfterAutoplayStopManualOnce = true;
                }
            });

            swiper.on('resize', function () {
                if (this.params.speedAfterAutoplayStop && this.params.loop) {
                    this.slideTo(this.activeIndex + 1, 0);
                    this.slideTo(this.activeIndex - 1, 0);
                    this.autoplay.start();
                }
            });

            swiper.init();

        }).addClass("js-swiper-inited");


        $s.each(function () {
            var $this = $(this);
            if (typeof $this.data("swiper-control") !== "undefined" && typeof $this.find('.swiper-container')[0].swiper !== "undefined") {
                var swiper = $this.find('.swiper-container')[0].swiper;
                var $thumbs_swiper = $($this.data("swiper-control"));
                var $thumbs_swiper_cs = $thumbs_swiper.find('.swiper-container');
                if ($thumbs_swiper_cs.length) {
                    var controls = [];
                    $thumbs_swiper_cs.each(function () {
                        var $thumbs_swiper_c = $(this);
                        if (typeof $thumbs_swiper_c[0].swiper !== "undefined") {
                            controls.push($thumbs_swiper_c[0].swiper);
                        }
                    });
                    swiper.controller.control = controls;
                }
            }

        });

        function progressCallback(swiper) {
            var $slider = $(swiper.$el).closest(".js-swiper");
            $slider.removeClass("js-swiper--start js-swiper--end");
            if (swiper.isBeginning) {
                $slider.addClass("js-swiper--start");
            }
            if (swiper.isEnd) {
                $slider.addClass("js-swiper--end");
            }
        }

        function swiper_bullet_timer_append(swiper, $all, $next, prefix, time) {
            $all.find('.' + prefix + '-timer').removeClass(prefix + '-timer-go').css('transition-duration', '');
            if (typeof time === 'undefined') {
                var time = (swiper.params.autoplay.delay + ((swiper.autoplay.once) ? swiper.params.speed : 0));//(swiper.params.autoplay.delay + swiper.params.speed);
            }
            var $timer = $next.find('.' + prefix + '-timer');
            if (!$timer.length) {
                var timer_inner = '<svg class="btn-pie" viewbox="0 0 52 52" width="52" height="52" xmlns="http://www.w3.org/2000/svg">' +
                    '<circle class="btn-pie__circle" stroke="#2F97FC" stroke-linecap="round" fill="none" cx="26" cy="26" r="23" transform="rotate(-90 26 26)" />' +
                    '</svg>';
                var $timer = $next.append('<div class="' + prefix + '-timer">'+timer_inner+'</div>').find('.' + prefix + '-timer');
            }
            if ($timer.length) {
                $timer.css({'cssText': 'transition-duration:' + time + 'ms !important; transition-timing-function: linear !important;'});// (this.params.autoplay.delay + (this.autoplay.once)?this.params.speed:0) + 'ms');
                $timer[0].offsetHeight;
                $timer.addClass(prefix + '-timer-go');
            }
        };

        $("body").on("responsive-update-no-animation", function () {
            delay_slider_resize(function () {
                $(".js-swiper.js-swiper-inited").each(function () {
                    if ($(this).data("reinitialize-on-resize") && typeof $(this).find(".swiper-container")[0].swiper !== "undefined") {
                        var swiper = $(this).find(".swiper-container")[0].swiper;
                        var index = swiper.realIndex;
                        swiper.destroy(true, true);
                        $(this).find('.swiper-slide-duplicate').remove();
                        slider_swiper_init($(this), function (e) {
                            this.slideTo(index, 0);
                        });
                    }
                });
            }, 300);
        });
    }
}

function slider_swiper_on_demand() {
    var $s = $(this).closest('.js-swiper-on-demand');
    if ($s.length) {
        slider_init($s);
    }
    else {
        var $s = $(this).closest('.js-swiper-on-demand-wrapper').find('.js-swiper-on-demand');
        slider_init($s);
    }
}

var delay_slider_resize = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

var delay_blazy_trigger = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();