
/*

 Click and touch events helpers

 */

function click_touch_init() {

    $(document).on("click.touchfocused", ".touchevents .js-touch-focused, .js-touch-focused-all", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused")) {
            if (!$o.hasClass("disable-prevent-link-clicks")) {
                e.preventDefault();
            }
            $(".js-touch-focused").not($o).not($o.closest(".js-touch-focused")).removeClass("focused");
            $o.addClass("focused");
        }
        else {
            if ($o.hasClass('js-touch-focused-toggle')) {
                $o.removeClass("focused");
            }
        }
    });

    $(document).on("click", ".touchevents .js-touch-focused a, .js-touch-focused-all a", function (e) {
        var $tf = $(this).closest(".js-touch-focused");
        if (!$tf.hasClass("focused") && !$tf.hasClass("disable-prevent-link-clicks")) {
            e.preventDefault();
        }
    });

    $(document).on("click touchstart", "*", function (e) {
        if (!$(e.target).closest(".js-touch-focused").length) {
            $(".js-touch-focused").removeClass("focused");
        }
    });

    $(document).on("click", ".js-prevent-default", function (e) {
        e.preventDefault();
    });

    $(document).on("click", ".js-stop-propagation", function (e) {
        e.stopPropagation();
    });

}



/*

Responsive design helpers

 */

function responsive_init() {
    responsive_update();
}

function responsive_update(force, no_animation) {
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body").addClass('no-transition no-animation');
    }
    if (typeof force === "undefined") force = false;
    var ww = window.innerWidth;
    if ($("body").data("ww") != ww || force)
    {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
        $("[data-place]").each(function(){
            var places = $(this).data("place");
            var breakpoints = Object.keys(places).map(function(value) {
                return parseInt(value);
            }).sort(function(a,b) {
                return a - b;
            }).reverse();
            for (i in breakpoints) {
                if (window.matchMedia("(min-width: "+breakpoints[i]+"px)").matches) {
                    if ($(places[breakpoints[i]]).length)  {
                        if (!$(this).next(places[breakpoints[i]]).length) {
                            $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                        }
                    }
                    break;
                }
            }
        });

        $("body").trigger("responsive-update");
        if (no_animation) {
            $("body").trigger("responsive-update-no-animation");
        }

        $("body").data("ww", ww);
    }
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body")[0].offsetHeight;
        $("body").removeClass('no-transition no-animation');
    }
}


/*

Scroll Lazy Loading

 */

function blazy_init() {

    $('.js-blazy-revalidate-on-scroll').on('scroll', function(){
        blazy_revalidate_on_scroll_delay(function(){
            if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                Blazy.revalidate();
            }
        }, 50);
    });

    window.Blazy = new Blazy({
        offset: 0,
        success: function(ele){
            var $o = $(ele).closest(".img-to-bg");
            img_to_bg($o);
            $o.addClass('img-to-bg-lazy-loaded');
            //console.log('Blazy', ele, 'success');
            blazy_callback(ele);
        }
        , error: function(ele, msg){
            //console.log('Blazy', ele, msg);
            blazy_callback(ele);
            if(msg === 'missing'){
                //console.log(ele, msg);
            }
            else if(msg === 'invalid'){
                //console.log(ele, msg);
            }
        }
    });

    $(window).on('resize', function(){
        blazy_revalidate_delay(function(){
            blazy_revalidate();
        }, 100);
    });

    $(document).on('shown.bs.modal', function () {
        blazy_revalidate();
    });

    $('.js-slick-slider-revalidate-image').on('init reInit setPosition', function(event){
        blazy_revalidate();
    });
}

function blazy_callback(ele) {
    var callback = $(ele).not('.blazy-callback-executed').data('src-callback');
    if (callback) {
        var callback_params = $(ele).data('src-callback-params');
        if (!callback_params) {
            callback_params = [];
        }
        var fn = getFunctionFromString(callback);
        if (typeof fn === "function") {
            $(ele).addClass('blazy-callback-executed');
            fn.apply(ele, callback_params);
        }
    }
}

function blazy_revalidate() {
    if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
        Blazy.revalidate();
    }
}

var blazy_revalidate_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

var blazy_revalidate_on_scroll_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

// Get function from string, with or without scopes (by Nicolas Gauthier)
window.getFunctionFromString = function(string)
{
    var scope = window;
    var scopeSplit = string.split('.');
    for (i = 0; i < scopeSplit.length - 1; i++)
    {
        scope = scope[scopeSplit[i]];

        if (scope == undefined) return;
    }

    return scope[scopeSplit[scopeSplit.length - 1]];
};




/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o, context) {

    if (!$("body").hasClass("img-to-bg-inited")) {

        $(window).on("resize", function(){
            delay_img_to_bg(function(){
                img_to_bg($(".img-to-bg-resp"));
            }, 100);
        });

        $("body").addClass("img-to-bg-inited");
    }

    if (typeof $o === "undefined" || !$o) $o = $(".img-to-bg", context);
    $o.each(function () {
        var $imgtobg = $(this);
        var $img = $imgtobg.find("> img").first();
        if (!$img.length) {
            $img = $imgtobg.find("> picture img").first();
        }
        var src = "";
        if ($img.length) {
            var src = $img[0].currentSrc;
        }
        if (!src) {
            src = $img.attr('src');
        }
        if ($img.length && src) {
            $imgtobg.css("background-image", "url('" + src + "')");
            $imgtobg.addClass("img-to-bg--inited");
        }
    });
}

var delay_img_to_bg = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();


/*

Scroll to needed objects

 */

function goto_init() {

    if (location.hash && location.hash.length) {
        var $o = $(location.hash);
        var h = $('.js-goto-offset-on-load').not('.js-goto-offset').first().outerHeight();
        console.log(h);
        goto_object($o, 0, (h)?-1*h:0);
    }

    $(document).on("click", ".js-goto", function (e) {
        var href = $(this).attr("href");
        var hash = href.split('#');
        if (hash.length > 1) {
            hash = '#'+hash.pop();
            var $o = $(hash);
            if ($o.length) {
                e.preventDefault();
                var $this = $(this);
                if ($this.closest(".goto-list").length)
                {
                    $this.closest(".goto-list").find("li").removeClass("active");
                    $this.closest("li").addClass("active");
                    tabs_update_pointer($this.closest(".tabs"));
                }
                if ($this.closest(".goto-hash-change").length)
                {
                    location_hash_update($this.attr("href"));
                }
                goto_object($o);
            }
        }
    });
}

function goto_object($o, speed, doffset)
{
    if (typeof speed === 'undefined') {
        speed = 700;
    }
    if ($o.length)
    {
        var offset = $o.offset().top;
        if (typeof doffset !== 'undefined') {
            offset += doffset;
        }
        var $offset = $(".js-goto-offset").first();
        if ($offset.length) {
            offset -= $offset.outerHeight();
        }
        if ($o.data("goto-offset-element")) {
            offset -= $($o.data("goto-offset-element")).outerHeight();
        }
        goto_offset(offset, speed, $o);
    }
}

function goto_offset(offset, speed, $o)
{
    if (typeof speed === 'undefined') {
        speed = 700;
    }
    $("html, body").stop(true, true).animate({scrollTop: offset}, speed, function(){
        $(this).trigger('goto-end');
        if ($o) {
            $o.trigger('goto-end');
        }
    });
}


/*

Actions on Scroll

 */

function scroll_animations() {
    body_scrolled();
}

function body_scrolled() {
    requestAnimationFrame(function(){
        var $o = $(".js-header-on-scroll");
        if ($o.length) {
            var st = $(window).scrollTop();
            var scrolled_condition = st > 0;
            var wh = window.innerHeight;
            if (scrolled_condition) {
                if (!$o.hasClass("scrolled")) $o.addClass("scrolled");
            }
            else {
                if ($o.hasClass("scrolled")) $o.removeClass("scrolled");
            }
            var scrolled_condition = st > wh;
            if (scrolled_condition) {
                if (!$o.hasClass("scrolled-fold")) $o.addClass("scrolled-fold");
            }
            else {
                if ($o.hasClass("scrolled-fold")) $o.removeClass("scrolled-fold");
            }
            scroll_back_header($o);
        }
    });
}

function scroll_back_header($o) {
    var y_offset = window.pageYOffset;
    this.menuScrolled = y_offset > 0;
    this.menuHidden = y_offset > $('.header-replace').height() && y_offset > this.oldScrollPosition;
    if (y_offset != this.oldScrollPosition) {
        // Uncomment to show it only on scroll back.
        // $o.toggleClass("header--hidden", this.menuHidden);
        $o.toggleClass("header--scrolled", this.menuScrolled);
    }
    this.oldScrollPosition = y_offset;
}

function scroll_back_header_hide($o) {
    var y_offset = window.pageYOffset;
    this.menuHidden = y_offset > $('.header-replace').height();
    $o.toggleClass("header--hidden", this.menuHidden);
}



/*

Header Init

*/

function header_init() {

    $(document).on("click", ".js-menu-switcher", function (e) {
        e.preventDefault();
        $("html").toggleClass("menu-overlay-active");
        if ($("html").hasClass("menu-overlay-active")) {
            bodyScrollLock.disableBodyScroll($('.menu-overlay__content')[0]);
        }
        else {
            bodyScrollLock.enableBodyScroll($('.menu-overlay__content')[0]);
        }
    });

    $(document).on("click", ".js-menu-hide", function (e) {
        e.preventDefault();
        menu_overlay_hide();
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".menu-overlay, .js-menu-switcher").length) {
            if ($("html").hasClass("menu-overlay-active")) {
                menu_overlay_hide();
            }
        }
    });

    $(document).on("touchmove", ".menu-overlay", function (e) {
        e.stopPropagation();
    });

    $(window).on("scroll", function (e) {
        if ($("html").hasClass("menu-overlay-active")) {
            menu_overlay_hide();
        }
    });
}

function menu_overlay_hide() {
    $("html").removeClass("menu-overlay-active");
    bodyScrollLock.enableBodyScroll($('.menu-overlay__content')[0]);
}



/*

Fix buggy 100vh behaviour on mobiles

 */

function fix_100vh_init() {
    if (!Modernizr.touchevents) return;

    fix_100vh();
    $("body").data("fix_100vh_ww", $(window).width());

    $(window).on("resize", function (e) {
        if ($("body").data("fix_100vh_ww") != $(window).width()) {
            fix_100vh();
        }
        $("body").data("fix_100vh_ww", $(window).width());
    });
}

function fix_100vh() {
    $(".js-fix-100vh").each(function(){
        $(this).css("height", window.innerHeight);
    });
    $(".js-fix-100vh-minus-header").each(function(){
        $(this).css("height", window.innerHeight - $('.header + .header-replace')[0].offsetHeight);
    });
    $(".js-fix-100vh-min").each(function(){
        $(this).css("min-height", window.innerHeight);
    });
    $(".js-fix-100vh-max").each(function(){
        $(this).css("max-height", window.innerHeight);
    });
}




/*

Expanded Blocks Functionality

 */

function expand_it_init()
{
    //expand_it_init_prepare(null, true);
    window['expand_it_window_width'] = $(window).width();
    $(window).on("resize", function () {
        if($(this).width() != window['expand_it_window_width']){
            expand_it_init_prepare(null, true);
            window['expand_it_window_width'] = $(this).width();
        }
    });

    $(document).on("click expand-it", ".expand-it", function(e){
        if (!$(this).data('no-prevent-default')) {
            e.preventDefault();
        }
        var $o = $($(this).data("expand-selector"));
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-wrapper").find(".expand-it-container").eq(0);
        }
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-container");
        }
        if (!$o.length)
        {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) return;

        expand_it_trigger($(this), $o, e.type === "expand-it");
    });

    $(document).on("-webkit-transitionend transitionend", ".expand-it-container", function(e){
        var $o = $(e.target);
        if ($o.hasClass('expand-it-container')) {
            if ($o.hasClass("before-transition")) {
                expand_it_height_check($o);
            }
            expand_it_init_prepare($o.closest('.expand-it-container'), true);
            $(e.target).removeClass("before-transition");
            $(e.target).trigger("expandAfter");
        }
    });

    if (location.hash && location.hash.substr(0, 1).match(/\w/i))
    {
        if ($(location.hash).filter(".expand-it-wrapper").length)
        {
            var $o = $(location.hash);
            var $loc_link = $(".expand-it[href='"+location.hash+"']");
            if (!$loc_link.length)
            {
                $loc_link = $o.filter(".expand-it-wrapper").find(".expand-it");
            }
            if ($loc_link.not(".active").filter(":visible").length)
            {
                setTimeout(function(){
                    $loc_link.trigger("click");
                }, 300)
            }
        }
    }

    $(document).on("expand-it-open", function (e) {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
    });
}

function expand_it_height_check($o) {
    if ($o.hasClass("expand-it-container") && !$o.hasClass("expand-it-container-overflow-hidden"))
    {
        var height_default = 0;
        var current_height = parseInt($o.css("max-height"), 10);
        if ($o.find(".expand-it-container-height-default").length)
        {
            height_default = $o.find(".expand-it-container-height-default").height();
        }
        $o.toggleClass("overflow-visible", current_height > height_default);
    }
    if ($o.hasClass("expand-it-container") && $o.hasClass("expand-it-container-max-height-auto"))
    {
        var id = $o.attr("id");
        setTimeout(function(){
            removeCSSRule("rule-"+id);
        }, 300);
    }
}

function expand_it_trigger($handler, $o, soft) {

    var id = $o.attr("id");
    if (!id)
    {
        id = "id"+(new Date()).getTime() + $o.text().length;
        $o.attr("id", id);
    }
    if ($o.hasClass('expand-it-container-ignore')) {
        return;
    }
    expand_it_init_prepare($o, true);
    height = $o.find(".expand-it-inner").eq(0).outerHeight(true);
    $o[0].offsetHeight;
    $o.addClass("no-transition-only-this");
    $o[0].offsetHeight;
    if (!$o.hasClass("expand-it-container-overflow-hidden"))
    {
        $o.removeClass("overflow-visible");
    }
    if ($o.hasClass("active"))
    {
        $o.removeClass("overflow-visible");
    }
    $o[0].offsetHeight;
    $o.removeClass("no-transition-only-this");
    if (!$o.hasClass("active") && !$("#rule-"+id).length) {
        addCSSRule("rule-"+id, "#"+id+".active { max-height: "+ height+"px; }");
    }
    $o[0].offsetHeight;

    if ($handler.attr("data-label")) {
        var label = $handler.html();
        $handler.html($handler.attr("data-label"));
        $handler.attr("data-label", label);
    }
    $handler.toggleClass("active");
    $(".expand-it.active[href='#"+$o.attr("id")+"']").not($handler).toggleClass("active");

    var $wrapper = $o.closest(".expand-it-wrapper");

    if (!soft)
    {
        $o.trigger("expandBefore");
        $o.addClass("before-transition").toggleClass("active").siblings(".expand-it-container").each(function(){
            $(".expand-it.active[href='#"+$handler.attr("id")+"']").trigger("expand-it");
            $handler.addClass("before-transition").removeClass("active");
        });
        var is_active = $o.hasClass("active");
        $wrapper.toggleClass("active", is_active);
        if ($wrapper.hasClass("expand-it-wrapper-collapse-siblings"))
        {
            $wrapper.siblings(".expand-it-wrapper").each(function(){
                var $this = $(this).find(".expand-it-container").first();
                if ($(this).find(".expand-it").length)
                {
                    $(this).find(".expand-it.active").trigger("expand-it");
                }
                else
                {
                    var $this_other = $(".expand-it.active[href='#"+$this.attr("id")+"']");
                    if ($this_other.length) {
                        $this_other.trigger("expand-it");
                    }
                    else {
                        var $this_other = $(this).find('.expand-it-pseudo')/*.filter(function() {
                            return $(this).parent($this).length === 0;
                        })*/;
                        if ($this_other.length) {
                            expand_it_trigger($this_other, $this, true);
                        }
                    }
                    if ($this_other.length) {
                        $this_other.removeClass("active");
                    }
                }
                $this.addClass("before-transition").removeClass("active");
                $(this).removeClass("active");
            });
            if ($wrapper.hasClass("active")) {
                setTimeout(function(){
                    var $sticky = $(".js-goto-offset-expand");
                    var offset = 0;
                    if ($sticky.length) {
                        $sticky.each(function(){
                            offset = Math.max(offset, $sticky.outerHeight() + parseInt($sticky.css('top'), 10));
                        });
                    }
                    if ($wrapper.offset().top < $(window).scrollTop() + offset) {
                        goto_object($wrapper, 400, -1*offset - parseInt($wrapper.css('margin-top'), 10));
                    }
                }, 400);
            }
        }
        if (($o.hasClass("active") || $o.hasClass("expand-it-container--scroll-to-always")) && $handler.hasClass("expand-it-scroll-to"))
        {
            goto_object($o, 500, 'easeInOutQuad');
        }
        if ($handler.hasClass("expand-it-hash-change"))
        {
            if (is_active)
            {
                if ($handler.attr("href"))
                {
                    location_hash_update($handler.attr("href"));
                }
                else if ($wrapper.attr("id"))
                {
                    location_hash_update("#" + $wrapper.attr("id"));
                }
            }
            else
            {
                var $tabpane = $handler.closest(".tab-pane");
                if ($tabpane.length && $tabpane.attr("id"))
                {
                    location_hash_update("#"+$tabpane.attr("id"));
                }
                else
                {
                    location_hash_remove();
                }
            }
        }
        $o.trigger('expand-it-'+((is_active)?'open':'close'));
    }
}

function expand_it_init_prepare($c, force) {
    if (typeof $c === "undefined" || !$c) $c = $(".expand-it-container");
    if (!force) {
        $c = $c.not(".expand-it-container-prepared");
    }
    var rules = '';
    $c.each(function(){
        var $o = $(this);
        var id = $o.attr("id");
        if (!id)
        {
            id = "id"+(new Date()).getTime() + $o.text().length;
            $o.attr("id", id);
        }
        //height = $o.find(".expand-it-inner").outerHeight(true);
        height = $o.find(".expand-it-inner")[0].clientHeight;
        rules += " #"+id+".active { max-height: "+ height+"px; } ";
        $o.addClass("expand-it-container-prepared");
    });
    if (rules) {
        addCSSRule("rule-expand-it", rules);
    }
}



function misc_init() {
    random_fly_init();
}

function random_fly_init() {

    $('[data-random-fly]').each(function () {
        var $this = $(this);
        var defaults = {
            pos: 0.1,
            scale: 0.1,
            rotate: 0,
            opacity: 0.1,
            duration: 2000,
            ease: 'linear',
            random: {
                pos: true,
                scale: true,
                rotate: true,
                opacity: true
            }
        };
        var options = $this.data('random-fly');
        if (options) {
            options = $.extend(true, {}, defaults, options);
        }
        $this.css('transition-timing-function', options.ease);
        $this.css('transition-duration', options.duration+'ms');
        requestAnimationFrame(function(){
            tick($this, options);
        });
        var timer = setInterval(function(){
            requestAnimationFrame(function(){
                tick($this, options);
            });
        }, options.duration);
    });

    function tick($this, options) {
        var x = ((Math.random() > 0.5)?-1:1)*Math.random()*options.pos*100;
        var y = ((Math.random() > 0.5)?-1:1)*Math.random()*options.pos*100;
        var rotate = (1 - Math.random()*options.rotate);
        var scale = (1 - Math.random()*options.scale);
        if (options.opacity_scale_link) {
            var opacity = Math.max((1 - (1 - scale)*options.opacity), 0);
        }
        else {
            var opacity = (1 - Math.random()*options.opacity);
        }
        $this.css('transform', 'translate('+x+'%, '+y+'%) rotate('+(360 - rotate*360)+'deg) scale('+scale+')');
        $this.css('opacity', opacity);
    }
}

function attachCaseStudyTabs() {
    const caseStudyTabs = document.querySelector('[data-druim-selector="case-study-tabs"]');
    if (caseStudyTabs) {
        new DruimTabs(caseStudyTabs);
    }
}